import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { toast } from 'sonner';
import { z } from 'zod';

export const publicClient = axios.create({
  baseURL: `${import.meta.env.VITE_API_BASE_URL}/api`,
  headers: {
    Accept: 'application/json',
  },
});

export const protectedClient = axios.create({
  baseURL: `${import.meta.env.VITE_API_BASE_URL}/api`,
  headers: {
    Accept: 'application/json',
  },
});

// Attach the latest token dynamically
protectedClient.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('access_token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

protectedClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      localStorage.removeItem('access_token');
    }
    return Promise.reject(error);
  },
);

export enum ApiCallType {
  Protected,
  Public,
}

interface ApiResponse<T> {
  data: T;
  status: number;
  statusText: string;
}

export class ApiClient {
  private static sharedAxiosInstance: AxiosInstance;
  private readonly axiosInstance: AxiosInstance;

  constructor(callType: ApiCallType) {
    const axiosConfig: AxiosRequestConfig = {
      baseURL: `${import.meta.env.VITE_API_BASE_URL}/api`,
      headers: {
        Accept: 'application/json',
      },
    };

    if (
      callType === ApiCallType.Protected &&
      localStorage.getItem('access_token')
    ) {
      axiosConfig.headers = {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        Accept: 'application/json',
      };
    }

    if (!ApiClient.sharedAxiosInstance) {
      ApiClient.sharedAxiosInstance = axios.create(axiosConfig);

      ApiClient.sharedAxiosInstance.interceptors.response.use(
        (response) => response, // Return successful response
        (error) => {
          if (error.response?.status === 401) {
            localStorage.removeItem('access_token');
            window.location.href = '/auth/login';
          }
          return Promise.reject(error); // Propagate error
        },
      );
    }

    this.axiosInstance = ApiClient.sharedAxiosInstance;
  }

  private handleResponse<T>(
    response: AxiosResponse<T>,
    responseSchema: z.Schema<T>,
  ): ApiResponse<T> {
    try {
      const parsedData = responseSchema.parse(response.data) as unknown as T;
      return {
        data: parsedData,
        status: response.status,
        statusText: response.statusText,
      };
    } catch (error) {
      if (error instanceof z.ZodError) {
        // Handle ZodError (e.g., log, throw custom error)
        console.error('Data validation failed:', error);
        throw new Error('Invalid server response');
      }
      throw error; // Re-throw other errors
    }
  }

  async get<T>(
    url: string,
    responseSchema: z.Schema<T>,
    config?: AxiosRequestConfig,
  ): Promise<ApiResponse<T>> {
    const response = await this.axiosInstance.get<T>(url, config);
    return this.handleResponse<T>(response, responseSchema);
  }

  async post<T>(
    url: string,
    data: any,
    responseSchema: z.Schema<T>,
    config?: AxiosRequestConfig,
  ): Promise<ApiResponse<T>> {
    const response = await this.axiosInstance.post<T>(url, data, config);
    return this.handleResponse<T>(response, responseSchema);
  }
}

export const handleRequestErrors = (err: any) => {
  console.error(err);

  let errorMsg = '';

  if (err.response) {
    errorMsg = err.response.data.error || err.response.data.message;
    toast.error(errorMsg || JSON.stringify(err.response.data));
  }
};
