import { createBrowserRouter, Navigate } from 'react-router-dom';
import { lazy } from 'react';
import LazyLoading from '@/lazy-loading.tsx';

//auth routes
const AuthLayout = lazy(() => import('@/layouts/auth/auth-layout.tsx'));
const SignUp = lazy(() => import('@/pages/auth/sign-up'));
const ForgetPassword = lazy(() => import('@/pages/auth/forget-password.tsx'));
const ResetPassword = lazy(() => import('@/pages/auth/reset-password.tsx'));
const VerifyEmailNotice = lazy(
  () => import('@/pages/auth/verify-email-notice.tsx'),
);
const VerifyEmailFailed = lazy(
  () => import('@/pages/auth/verify-email-failed.tsx'),
);
const VerifyEmailCallback = lazy(
  () => import('@/pages/auth/verify-email-callback.tsx'),
);
const Authenticate = lazy(() => import('@/pages/auth/authenticate'));
const GoogleCallback = lazy(() => import('@/pages/auth/google-callback.tsx'));
const RedirectIfAuthenticated = lazy(
  () => import('@/pages/auth/redirect-if-authenticated.tsx'),
);
const RedirectIfAuthorized = lazy(
  () => import('@/pages/auth/redirect-if-authorized.tsx'),
);
const Login = lazy(() => import('@/pages/auth/login.tsx'));
const Landing = lazy(() => import('@/pages/recruitment/landing/landing.tsx'));
const CompleteProfileOnWelcome = lazy(
  () => import('@/pages/candidates/profile/complete-profile-on-welcome.tsx'),
);

const ProtectedRoute = lazy(() => import('@/pages/auth/protected-route.tsx'));
const ForbiddenRoute = lazy(() => import('@/pages/auth/forbidden-route.tsx'));
const RedirectIfNoProfile = lazy(
  () => import('@/pages/auth/redirect-if-no-profile.tsx'),
);

const RecruitmentLayout = lazy(
  () => import('@/layouts/recruitment/recruitment-layout.tsx'),
);
const CandidacyLayout = lazy(
  () => import('@/layouts/candidates/candidates-layout.tsx'),
);

//recruiter vacancies routes
const Vacancies = lazy(() => import('@/pages/recruitment/vacancies'));
const CreateVacancy = lazy(
  () => import('@/pages/recruitment/vacancies/create-vacancy.tsx'),
);
const VacancyDetails = lazy(
  () => import('@/pages/recruitment/vacancies/vacancy-details'),
);
const EditVacancy = lazy(
  () => import('@/pages/recruitment/vacancies/edit-vacancy.tsx'),
);

//candidate vacancies routes
const CandidateVacancies = lazy(
  () => import('@/pages/candidates/candidate-vacancies'),
);
const CandidateVacancyDetails = lazy(
  () =>
    import(
      '@/pages/candidates/candidate-vacancies/candidate-vacancy-details.tsx'
    ),
);
const CandidateApplications = lazy(
  () => import('@/pages/candidates/candidate-applications'),
);
const CandidateApplicationDetails = lazy(
  () =>
    import(
      '@/pages/candidates/candidate-applications/candidate-application-details.tsx'
    ),
);

//candidates routes
const Candidates = lazy(() => import('@/pages/recruitment/candidates'));
const CandidateDetails = lazy(
  () => import('@/pages/recruitment/candidates/candidate-details.tsx'),
);

const Applications = lazy(() => import('@/pages/recruitment/applications'));
const ApplicationDetails = lazy(
  () => import('@/pages/recruitment/applications/application-details.tsx'),
);

//profile routes
const Profile = lazy(() => import('@/pages/candidates/profile'));
const EditProfile = lazy(
  () => import('@/pages/candidates/profile/edit-profile.tsx'),
);

//errors routes
const Forbidden = lazy(() => import('@/pages/errors/forbidden.tsx'));
const NotFound = lazy(() => import('@/pages/errors/not-found.tsx'));

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <LazyLoading>
        <RedirectIfAuthorized>
          <CandidacyLayout />
        </RedirectIfAuthorized>
      </LazyLoading>
    ),
    children: [
      {
        index: true,
        element: (
          <LazyLoading>
            <CandidateVacancies />
          </LazyLoading>
        ),
      },
      {
        path: 'vacancies/:id',
        element: (
          <LazyLoading>
            <CandidateVacancyDetails />
          </LazyLoading>
        ),
      },
    ],
  },
  {
    path: '/authenticate',
    element: (
      <LazyLoading>
        <Authenticate />
      </LazyLoading>
    ),
  },
  {
    path: '/email/verify/:id/:hash',
    element: (
      <LazyLoading>
        <VerifyEmailCallback />
      </LazyLoading>
    ),
  },
  {
    path: '/auth/google-callback',
    element: (
      <LazyLoading>
        <GoogleCallback />
      </LazyLoading>
    ),
  },
  {
    path: '/auth',
    element: (
      <LazyLoading>
        <RedirectIfAuthenticated>
          <AuthLayout />
        </RedirectIfAuthenticated>
      </LazyLoading>
    ),
    children: [
      {
        path: '/auth/login',
        element: (
          <LazyLoading>
            <Login />
          </LazyLoading>
        ),
      },
      {
        path: 'sign-up',
        element: (
          <LazyLoading>
            <SignUp />
          </LazyLoading>
        ),
      },
      {
        path: 'forget-password',
        element: (
          <LazyLoading>
            <ForgetPassword />
          </LazyLoading>
        ),
      },
      {
        path: 'reset-password',
        element: (
          <LazyLoading>
            <ResetPassword />
          </LazyLoading>
        ),
      },
      {
        path: 'verify-email',
        element: (
          <LazyLoading>
            <VerifyEmailNotice />
          </LazyLoading>
        ),
      },
      {
        path: 'verify-email-failed',
        element: (
          <LazyLoading>
            <VerifyEmailFailed />
          </LazyLoading>
        ),
      },
    ],
  },
  {
    path: '/landing',
    element: (
      <LazyLoading>
        <Landing />
      </LazyLoading>
    ),
  },
  {
    path: '/recruitment',
    element: (
      <LazyLoading>
        <ProtectedRoute>
          <ForbiddenRoute allowedRoles={['recruiter']}>
            <RecruitmentLayout />
          </ForbiddenRoute>
        </ProtectedRoute>
      </LazyLoading>
    ),
    children: [
      {
        index: true,
        element: <Navigate to="candidates" replace />,
      },
      {
        path: 'candidates',
        element: (
          <LazyLoading>
            <Candidates />
          </LazyLoading>
        ),
      },
      {
        path: 'candidates/:id',
        element: (
          <LazyLoading>
            <CandidateDetails />
          </LazyLoading>
        ),
      },
      {
        path: 'vacancies',
        element: (
          <LazyLoading>
            <Vacancies />
          </LazyLoading>
        ),
      },
      {
        path: 'vacancies/create',
        element: (
          <LazyLoading>
            <CreateVacancy />
          </LazyLoading>
        ),
      },
      {
        path: 'vacancies/:id',
        element: (
          <LazyLoading>
            <VacancyDetails />
          </LazyLoading>
        ),
      },
      {
        path: 'vacancies/:id/edit',
        element: (
          <LazyLoading>
            <EditVacancy />
          </LazyLoading>
        ),
      },
      {
        path: 'applications',
        element: (
          <LazyLoading>
            <Applications />
          </LazyLoading>
        ),
      },
      {
        path: 'applications/:id',
        element: (
          <LazyLoading>
            <ApplicationDetails />
          </LazyLoading>
        ),
      },
    ],
  },
  {
    path: '/candidates/complete-profile',
    element: (
      <LazyLoading>
        <ProtectedRoute>
          <ForbiddenRoute allowedRoles={['candidate']}>
            <CompleteProfileOnWelcome />
          </ForbiddenRoute>
          .
        </ProtectedRoute>
      </LazyLoading>
    ),
  },
  {
    path: '/candidates',
    element: (
      <LazyLoading>
        <ProtectedRoute>
          <ForbiddenRoute allowedRoles={['candidate']}>
            <RedirectIfNoProfile>
              <CandidacyLayout />
            </RedirectIfNoProfile>
          </ForbiddenRoute>
        </ProtectedRoute>
      </LazyLoading>
    ),
    children: [
      {
        index: true,
        element: <Navigate to="vacancies" replace />,
      },
      {
        path: 'vacancies',
        element: (
          <LazyLoading>
            <CandidateVacancies />
          </LazyLoading>
        ),
      },
      {
        path: 'vacancies/:id',
        element: (
          <LazyLoading>
            <CandidateVacancyDetails />
          </LazyLoading>
        ),
      },
      {
        path: 'applications',
        element: (
          <LazyLoading>
            <CandidateApplications />
          </LazyLoading>
        ),
      },
      {
        path: 'applications/:id',
        element: (
          <LazyLoading>
            <CandidateApplicationDetails />
          </LazyLoading>
        ),
      },
      {
        path: 'profile',
        element: (
          <LazyLoading>
            <Profile />
          </LazyLoading>
        ),
      },
      {
        path: 'profile/edit',
        element: (
          <LazyLoading>
            <EditProfile />
          </LazyLoading>
        ),
      },
    ],
  },

  {
    path: '/forbidden',
    element: (
      <LazyLoading>
        <Forbidden />
      </LazyLoading>
    ),
  },
  {
    path: '*',
    element: (
      <LazyLoading>
        <NotFound />
      </LazyLoading>
    ),
  },
]);

export default router;
