import { createContext, ReactNode, useContext, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import currentAuthUserQueryFn from '@/pages/auth/queries/current-auth-user-query.ts';
import GlobalLoading from '@/global-loading.tsx';

export interface CurrentAuthUser {
  id: number;
  name: string;
  email: string;
  avatar_url: string | null;
  role: string;
  profile?: any;
}

export interface CurrentAuthUserResponse {
  data: CurrentAuthUser;
}

interface AuthContextType {
  user?: CurrentAuthUserResponse | null;
  isAuthenticated: boolean;
}

const AuthContext = createContext<AuthContextType | null>(null);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const { data: user, isLoading } = useQuery<CurrentAuthUserResponse, Error>({
    queryFn: currentAuthUserQueryFn,
    queryKey: ['currentAuthUser'],
    retry: false, // Don’t retry on failure (e.g., no token)
    staleTime: 0, // Ensure fresh data on mount
  });

  const token = localStorage.getItem('access_token');
  const isAuthenticated = useMemo(
    () => !!token && Boolean(user),
    [token, user],
  );

  if (isLoading) {
    return <GlobalLoading />;
  }

  return (
    <AuthContext.Provider value={{ user, isAuthenticated }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) throw new Error('useAuth must be used within an AuthProvider');
  return context;
};
